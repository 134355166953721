import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '@layouts';
import { useStaticQuery, graphql } from 'gatsby';

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query GetRecentNotes {
			allMdx(sort: { fields: frontmatter___date, order: DESC }) {
				nodes {
					frontmatter {
						title
						date(fromNow: true)
					}
					id
					slug
				}
			}
		}
	`);

	const recentNotes = data?.allMdx?.nodes ?? {};

	console.log(recentNotes);

	return (
		<Layout>
			<h1>Remington Press</h1>
			<p>
				Aliquip duis occaecat pariatur do. Reprehenderit laboris sint quis
				laborum ut officia sint magna dolore. Qui aute veniam esse quis ad ex
				culpa nostrud commodo fugiat aute voluptate et. Ullamco ipsum id sunt
				nulla dolor eiusmod cupidatat magna nostrud aute sint esse. Elit aliqua
				laborum minim ea minim. Adipisicing incididunt enim non est nisi cillum
				reprehenderit nostrud minim et occaecat magna duis enim.
			</p>

			<h2>Recent posts</h2>

			<ul>
				{recentNotes.map((note) => {
					return (
						<li key={note.id}>
							<Link to={note.slug}>{note.frontmatter.title}</Link>
							<br />
							Posted {note.frontmatter.date}
						</li>
					);
				})}
			</ul>
		</Layout>
	);
};

export default IndexPage;
